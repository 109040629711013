import { Button, styled } from '@mui/material';

import { ReactComponent as ArrowIcon } from '../../static/svg/arrow_green.svg';
import CustomBadge from './customBadge';


const ExpandButton = ({ onClick, isOpen, label }) => {
  return (
    <StyledButton
      isOpen={isOpen}
      onClick={onClick}
    >
      {label && (
        <CustomBadge label={label} variant={'green'} />
      )}

      <ArrowIcon width={14} height={14} />
    </StyledButton>
  );
};

export default ExpandButton;

const StyledButton = styled(Button)(({ isOpen }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 2,

  height: "26px",
  padding: 6,
  paddingLeft: isOpen ? 6 : 4,
  
  borderRadius: "106px",
  border: "1px solid rgba(16, 190, 118, 0.30)",
  
  background: 'rgba(16, 190, 118, 0.10)',

  '&.MuiButtonBase-root': {
    minWidth: 'unset'
  }

}));