import { Checkbox, styled } from "@mui/material";

import check from '../../static/svg/check.svg';
import indeterminate from '../../static/svg/indeterminate.svg';

export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  boxSizing: 'border-box',
  width: "20px",
  height: "20px",
  borderRadius: "6px",
  opacity: 0.75,

  '&.MuiCheckbox-root:hover': {
    border: "2px solid #49CB96",
  },

  '&.MuiCheckbox-root': {
    border: "2px solid #C2E2D5",
  },

  '&.Mui-checked': {
    border: "2px solid rgba(255, 255, 255, 0.40)",
    background: `#10BE76 url(${check}) center center`,
  },

  '&.MuiCheckbox-indeterminate': {
    border: "2px solid rgba(255, 255, 255, 0.40)",
    background: `#10BE76 url(${indeterminate}) center center`,
  },

  '@media (max-width: 1440px)': {
    width: 16,
    height: 16
  },
}));