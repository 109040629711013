import { Box, styled } from '@mui/material';
import React from 'react';
import theme from '../../theme';

const backgroundByVariant = {
  green: theme.palette.green,
  red: theme.palette.red,
}

const CustomBadge = ({ label, variant, onClick, style }) => {
  return (
    <Count variant={variant} onClick={onClick} sx={style}>
      {label}
    </Count>
  );
};

export default CustomBadge;

const Count = styled(Box)(({ variant }) => ({
  width: 18,
  height: 18,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: 20,
  background: backgroundByVariant[variant] || 'gray',

  color: '#FFF',
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: 1,
}));