import { Box, Button, Menu, MenuItem, Typography, styled } from '@mui/material';
import React, { useState } from 'react';

import { ReactComponent as ArrowIcon } from '../../static/svg/arrow_black.svg';
import theme from '../../theme';
import { yearVariants } from '../../const/year';

const AnalyticsHeader = ({ updateFilter, year, setYear }) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleSelectClick(event) {
    setIsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  function handleSelectChange(year) {
    updateFilter({ year });
    setYear(year);
    handleSelectClose();
  }

  function handleSelectClose() {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };


  return (
    <Container>
      <HorizontalContainer>
        <Typography
          variant='bold'
          sx={{
            fontSize: 16,
            color: 'black.secondary'
          }}
        >Year:</Typography>

        <YearSelect
          id="basic-button"
          aria-controls={isMenuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={handleSelectClick}
        >
          <Typography
            sx={{ color: 'black.secondary' }}
          >{year}</Typography>
          <Box
            sx={{
              height: 18,
              transition: `transform .2s ${theme.transitions.cubic}`,
              transform: `rotate(${anchorEl ? 180 : 0}deg)`,
            }}
          >
            <ArrowIcon />
          </Box>
        </YearSelect>
      </HorizontalContainer>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleSelectClose}
        sx={{
          marginTop: 1,

          '& .MuiMenu-paper': {
            borderRadius: 4,
            border: "1px solid rgba(27, 47, 43, 0.10)",
            background: "#FFF",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.05)",
            width: 85,

          },

          '& .MuiList-root': {
            padding: 0,

          }
        }}
      >
        {
          yearVariants.map((year) => {
            return (
              <MenuItem
                key={year}
                onClick={() => handleSelectChange(year)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: '12px 14px',
                  width: 250,
                  borderBottom: "1px solid rgba(27, 47, 43, 0.10)",

                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 1000,
                    lineHeight: 1,
                    textTransform: "uppercase",
                  }}
                >
                  {year}
                </Typography>
              </MenuItem>

            )
          })
        }
      </Menu>
    </Container>
  );
};

export default AnalyticsHeader;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: 20,

  padding: '10px 18px',

}));

const HorizontalContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
}));

const YearSelect = styled(Button)(() => ({
  height: 36,
  padding: "0 10px",
  borderRadius: 16,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,

  backgroundColor: 'white',

  cursor: "pointer",
  transition: `background-color .1s linear`,

  '&:hover': {
    backgroundColor: 'rgba(27, 47, 43, 0.04)',
  }
}));