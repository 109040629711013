export function getBackgroundColors(graphMin, pickWidth, endsOfMonths) {
  return [
    {
      color: '#D4F5FF',
      start: graphMin,
      width: pickWidth * endsOfMonths?.[1] * 2,
    },
    {
      color: '#D8F2D9',
      start: graphMin + pickWidth * endsOfMonths?.[1] * 2,
      width: pickWidth * (endsOfMonths?.[4] - endsOfMonths?.[1]) * 2,
    },
    {
      color: '#FFDADA',
      start: graphMin + pickWidth * endsOfMonths?.[4] * 2 - 1,
      width: pickWidth * (endsOfMonths?.[7] - endsOfMonths?.[4]) * 2,
    },
    {
      color: '#FFE3CA',
      start: graphMin + pickWidth * endsOfMonths?.[7] * 2 - 1,
      width: pickWidth * (endsOfMonths?.[10] - endsOfMonths?.[7]) * 2,
    },
    {
      color: '#D4F5FF',
      start: graphMin + pickWidth * endsOfMonths?.[10] * 2 - 1,
      width: pickWidth * (endsOfMonths?.[11] - endsOfMonths?.[10]) * 2,
    },
  ];
}

// export function getTableBackgroundColors(graphMin, pickWidth) {
//   return [
//     {
//       color: '#D4F5FF',
//       start: graphMin,
//       width: pickWidth * 18,
//     },
//     {
//       color: '#D8F2D9',
//       start: graphMin + pickWidth * 9 * 2,
//       width: pickWidth * 13 * 2 + 1,
//     },
//     {
//       color: '#FFDADA',
//       start: graphMin + pickWidth * 22 * 2,
//       width: pickWidth * 13 * 2 + 1,
//     },
//     {
//       color: '#FFE3CA',
//       start: graphMin + pickWidth * 35 * 2,
//       width: pickWidth * 13 * 2 + 1,
//     },
//     {
//       color: '#D4F5FF',
//       start: graphMin + pickWidth * 48 * 2,
//       width: pickWidth * 4.5 * 2,
//     },
//   ];
// }