import React from 'react';


const LayOut = ({children}) => {
  return(
    <>
      {/* <Header/> */}
      <main>{children}</main>    
    </>
  )
}

export default LayOut