import { Tooltip, styled, tooltipClasses } from "@mui/material";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: '#1B2F2B',
    borderRadius: '2px 0 0 0',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "normal",

    backgroundColor: '#1B2F2B',
  },
}));

export default BootstrapTooltip;