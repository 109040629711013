import * as React from 'react';
import styled from '@emotion/styled';
import { Typography, AppBar, Box, Button } from '@mui/material';

import { ReactComponent as SearchIcon } from '../../static/svg/search.svg';
import { userAtom } from '../atoms/user';
import { useRecoilValue } from 'recoil';
import { getFilters } from './utils/filters';
import { countersAtom } from '../atoms/counters';
import { localizationAtom } from '../atoms/localization';
import { specialSymbolsForCounterByKey } from './consts/counters';


function TableHeader({
  updateFilter,
  searchParams,
  setSearchValue,
  searchValue,
}) {
  const user = useRecoilValue(userAtom);
  const counters = useRecoilValue(countersAtom);
  const localization = useRecoilValue(localizationAtom);

  const isUserSuperadmin = user?.role === "superadmin";
  const isUserManager = user?.role === "manager";

  const [filters, setFilters] = React.useState(getFilters((isUserSuperadmin || isUserManager)));

  const countersByFilter = {
    'All': {
      'VT': 'unique_orders_this_month_today_visit',
      'IP ES': 'unique_orders_this_month_IP_ES',
    },
    'Today': [
      {
        'Today': 'visitors_created_and_visit_today',
        'Total': 'visitors_created_today',
      },
      {
        'Users': 'unique_orders_created_today',
        'VT': 'today_visit',
      },
    ],
    'Registered': {
      [localization['counter_this_week']]: 'registered_this_week',
      [localization['counter_last_week']]: 'registered_last_week',
    },
    'New': (isUserSuperadmin || isUserManager) ? (
      [
        {
          [localization['counter_today']]: 'visitors_visit_today',
          [localization['counter_total']]: 'visitors',
        },
      ]
    ) : (
      {
        [localization['counter_today']]: 'visitors_visit_today',
        [localization['counter_total']]: 'visitors',
      }
    ),
  };

  const status = searchParams.get('status')

  const counterData = counters?.[searchParams.get('club') ? searchParams.get('club') : 'all'];
  const counterNames = countersByFilter[status];

  const getCounterElement = React.useCallback(([name, key], index, arr) => {
    const isColored = key === 'registered_last_week' && counterData?.[key] > 0;

    return (
      <Counter isColored={isColored}>
        <Typography
          variant='bold'
          sx={{
            fontSize: 14,
            color: isColored ? 'white' : '#1B2F2B',
          }}
        >
          {name}
        </Typography>

        <Typography
          sx={{ fontSize: 14 }}
        >
          {counterData?.[key] || 0}{specialSymbolsForCounterByKey[key]}
        </Typography>
      </Counter>
    );
  }, [counterData]);

  function handleChangeFilter(filter) {
    updateFilter({ status: filter });
    setSearchValue("");
  }

  React.useEffect(() => {
    setFilters(getFilters((isUserSuperadmin || isUserManager)));
  }, [user]);


  return (
    <AppBar position="static" color='secondary' elevation={0}>
      <Container>
        <Filters>
          {filters.map((filter) => (
            <FilterButton
              isActive={status === filter}
              variant={filter}
              onClick={() => handleChangeFilter(filter)}
            >
              {(isUserSuperadmin || isUserManager)
                ? filter
                : localization[`status_${filter.toLowerCase()}`]}
            </FilterButton>
          ))}
        </Filters>

        {counterNames && (
          <Counters>
            {counterNames[0] ? (
              counterNames.map((counterNamesItem) => (
                <CounterGroup>
                  {Object.entries(counterNamesItem).map(getCounterElement)}
                </CounterGroup>
              ))
            ) : (
              <CounterGroup>
                {Object.entries(counterNames).map(getCounterElement)}
              </CounterGroup>
            )}
          </Counters>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            width: 380,
          }}
        >
          <Search>
            <SearchIcon />
            <Input
              placeholder={localization['placeholder']}
              onChange={(evt) => setSearchValue(evt.target.value)}
              value={searchValue}
            />
          </Search>

        </Box>
      </Container>
    </AppBar>
  );
}
export default TableHeader;

const Container = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',

  padding: '10px 18px',
  display: 'flex',
  justifyContent: 'space-between',

  gap: 10,

}));

const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
}));

const Filters = styled(HorizontalContainer)(({ theme }) => ({
  width: 380,
  height: 36,
  gap: 2,
}));

const FilterButton = styled(Button)(({ theme, isActive }) => ({
  minWidth: 'unset',
  position: 'relative',

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -10,
    left: 0,

    backgroundColor: '#11BC76',
    borderRadius: 10,
    width: isActive ? '100%' : 0,
    height: 4,
  }
}));

const Counters = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 10,

}));

const CounterGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "34px",

}));

const Counter = styled(Box)(({ isColored }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "6px",

  height: '100%',
  padding: "0px 12px",

  backgroundColor: isColored ? '#368362' : 'transparent',
  color: isColored ? 'white' : 'inherit',

  border: '1px solid rgba(27, 47, 43, 0.10)',

  '&:not(:last-child)': {
    borderRight: "none",
  },

  '&:first-child': {
    borderRadius: '16px 0 0 16px',
  },

  '&:last-child': {
    borderRadius: '0 16px 16px 0',
  },

}));

const Search = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',

  display: "flex",
  alignItems: "center",
  gap: "10px",

  width: "340px",
  height: "36px",
  padding: "0px 6px",

  borderRadius: "16px",
  border: "1px solid rgba(27, 47, 43, 0.10)",

  background: "var(--Main-white, #FFF)",

  transition: 'all ease .2s',

  '&:hover': {
    border: "1px solid rgba(15, 190, 118, 0.30)",
    boxShadow: "0px 0px 0px 4px rgba(15, 190, 118, 0.15)"
  },

  '&:has(input:focus)': {
    border: "1px solid #0FBE76",
    boxShadow: "0px 0px 0px 4px rgba(15, 190, 118, 0.15)"
  },

  '@media (max-width: 1440px)': {
    height: 32,
  },

}));

const Input = styled('input')(({ theme }) => ({
  color: '#434343',
  fontFamily: "Gilroy",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: 1,

  width: "100%",
  height: 30,
  opacity: .5,

  border: 'none',
  outline: 'none',

  backgroundColor: 'transparent',

  transition: 'all ease .2s',

  '&:focus': {
    opacity: 1
  },

  '@media (max-width: 1440px)': {
    fontSize: 14,
  },
}));