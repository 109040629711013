const backgroundByStatus = {
  today: '#F6F8FF',
  all: '#FFFBF4',
  registered: '#F5FFFB',
  new: '#F3F9FF',
  expired: '#FFF3F3',
  done: '#F3F3F3',
}

const conditionalRowStyles = (status = '', expandRow, shouldBeColored) => {
  const data = [
    {
      when: () => (true),
      style: (row) => {
        const isLastGroupedRow = (expandRow.includes(row.id_year) && row.is_last === true) || row.isLastInGroup;
        const shouldHaveBorder = row.is_last && !isLastGroupedRow;
        const style = {};

        if (row.shouldHaveAccentBackground) {
          style.backgroundColor = '#2A7C5A';

          if (shouldHaveBorder) {
            style.borderBottom = '1px solid rgba(255, 255, 255, 0.10)';
          }
          
          if (shouldBeColored || row.shouldBeColored) {
            style.backgroundColor = '#368362';
          }

        } else {
          if (shouldBeColored || row.shouldBeColored) {
            style.backgroundColor = backgroundByStatus[status.toLowerCase()];
          }

          if (shouldHaveBorder) {
            style.borderBottom = '1px solid rgba(27, 47, 43, 0.10)';
          }
          
          if (isLastGroupedRow) {
            style.borderBottom = '1px solid rgba(27, 47, 43, 0.06)';
          }
        }

        if (row.shouldHaveBoldTopBorder) {
          style.borderTop = '5px solid rgba(233, 243, 238, 1)';
        }

        if (!row.is_last) {
          style.paddingBottom = 0;
        }

        return style;
      }
    },

  ];

return data
}
export default conditionalRowStyles;