export const tableStyles = {
  table: {
    style: {
      border: '1px solid rgba(27, 47, 43, 0.10)',
      borderRadius: 24,
      overflow: 'hidden',

      backgroundColor: 'white',

      fontFamily: "Gilroy",
      color: 'rgba(27, 47, 43, 0.80)',
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "normal",
    }
  },
  headRow: {
    style: {
      backgroundColor: 'rgba(231, 243, 238, 1)',

      color: "rgb(27, 47, 43)",
      fontWeight: 1000,
      lineHeight: 1,
      textTransform: "uppercase",

      padding: '12px',
      minHeight: 'unset',
    },
  },
  rows: {
    style: {

      padding: '6px 12px',
      minHeight: 38,
      height: 38,

      borderBottom: '1px solid rgba(27, 47, 43, 0.10)',

      cursor: 'pointer',

      '&:hover': {
        backgroundColor: '#00000010',
      }

    }
  },
  headCells: {
    style: {
      fontSize: 14,
      padding: '0 0 0 10px',

      '@media (max-width: 1440px)': {
        fontSize: 12,
      },
    }
  },
  cells: {
    style: {
      color: "rgba(27, 47, 43, 0.80)",
      fontSize: 14,
      padding: '0 0 0 10px',
    }
  },
};
