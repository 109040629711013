import { Box, Button, Menu, MenuItem, Typography, styled } from '@mui/material';
import { useRecoilValue } from 'recoil';
import React, { useState } from 'react';

import { ReactComponent as ArrowIcon } from '../../static/svg/arrow_black.svg';
import theme from '../../theme';
import { localizationAtom } from '../atoms/localization';
import { monthVariants, yearVariants } from '../../const/year';
import { userAtom } from '../atoms/user';

const StatisticHeader = ({ updateFilter, month, setMonth }) => {
  const localization = useRecoilValue(localizationAtom);
  const user = useRecoilValue(userAtom);

  const [year, setYear] = useState(new Date().getFullYear());
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isMonthMenuOpen, setIsMonthMenuOpen] = useState(false);
  const [anchorElMonth, setAnchorElMonth] = useState(null);

  function handleSelectClick(event) {
    setIsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  function handleMonthSelectClick(event) {
    setIsMonthMenuOpen(true);
    setAnchorElMonth(event.currentTarget);
  };

  function handleSelectChange(year) {
    updateFilter({ year });
    setYear(year);
    handleSelectClose();
  }

  function handleSelectClose() {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  function handleMonthSelectChange(month) {
    setMonth(month);
    handleMonthSelectClose();
  }

  function handleMonthSelectClose() {
    setIsMonthMenuOpen(false);
    setAnchorElMonth(null);
  };

  if (user && user.role === "admin") {
    return (
      <Container />
    )
  }

  return (
    <Container>
      <Box sx={{
        minWidth: 300,
      }} />

      <FilterContainer>
        <HorizontalContainer>
          <Typography
            variant='bold'
            sx={{
              fontSize: 16,
              color: 'black.secondary'
            }}
          >{localization['month']}:</Typography>

          <FilterSelect
            id="basic-button"
            aria-controls={isMonthMenuOpen ? 'month-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMonthMenuOpen ? 'true' : undefined}
            onClick={handleMonthSelectClick}
          >
            <Typography
              sx={{ color: 'black.secondary' }}
            >{localization[month]}</Typography>
            <Box
              sx={{
                height: 18,
                transition: `transform .2s ${theme.transitions.cubic}`,
                transform: `rotate(${anchorElMonth ? 180 : 0}deg)`,
              }}
            >
              <ArrowIcon />
            </Box>
          </FilterSelect>
        </HorizontalContainer>


        <HorizontalContainer>
          <Typography
            variant='bold'
            sx={{
              fontSize: 16,
              color: 'black.secondary'
            }}
          >{localization['year']}:</Typography>

          <FilterSelect
            id="basic-button"
            aria-controls={isMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={handleSelectClick}
          >
            <Typography
              sx={{ color: 'black.secondary' }}
            >{year}</Typography>
            <Box
              sx={{
                height: 18,
                transition: `transform .2s ${theme.transitions.cubic}`,
                transform: `rotate(${anchorEl ? 180 : 0}deg)`,
              }}
            >
              <ArrowIcon />
            </Box>
          </FilterSelect>
        </HorizontalContainer>
      </FilterContainer>



      <Box sx={{
        minWidth: 300,
      }} />

      <Menu
        id="month-menu"
        anchorEl={anchorElMonth}
        open={isMonthMenuOpen}
        onClose={handleMonthSelectClose}
        sx={{
          marginTop: 1,

          '& .MuiMenu-paper': {
            borderRadius: 4,
            border: "1px solid rgba(27, 47, 43, 0.10)",
            background: "#FFF",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.05)",
            width: 185,

          },

          '& .MuiList-root': {
            padding: 0,

          }
        }}
      >
        {
          monthVariants.map((month) => {
            return (
              <MenuItem
                key={month}
                onClick={() => handleMonthSelectChange(month)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: '12px 14px',
                  width: 250,
                  borderBottom: "1px solid rgba(27, 47, 43, 0.10)",

                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 1000,
                    lineHeight: 1,
                    textTransform: "uppercase",
                  }}
                >
                  {localization[month]}
                </Typography>
              </MenuItem>

            )
          })
        }
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleSelectClose}
        sx={{
          marginTop: 1,

          '& .MuiMenu-paper': {
            borderRadius: 4,
            border: "1px solid rgba(27, 47, 43, 0.10)",
            background: "#FFF",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.05)",
            width: 85,

          },

          '& .MuiList-root': {
            padding: 0,

          }
        }}
      >
        {
          yearVariants.map((year) => {
            return (
              <MenuItem
                key={year}
                onClick={() => handleSelectChange(year)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: '12px 14px',
                  width: 250,
                  borderBottom: "1px solid rgba(27, 47, 43, 0.10)",

                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 1000,
                    lineHeight: 1,
                    textTransform: "uppercase",
                  }}
                >
                  {year}
                </Typography>
              </MenuItem>

            )
          })
        }
      </Menu>
    </Container>
  );
};

export default StatisticHeader;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: 20,

  height: 36,
  padding: '10px 18px',

}));

const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

const FilterContainer = styled(HorizontalContainer)(({ theme }) => ({
  gap: 40,

}));

const FilterSelect = styled(Button)(() => ({
  height: 36,
  padding: "0 10px",
  borderRadius: 16,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,

  backgroundColor: 'white',

  cursor: "pointer",
  transition: `background-color .1s linear`,

  '&:hover': {
    backgroundColor: 'rgba(27, 47, 43, 0.04)',
  }
}));