export function transformStatsForRechart(stats, year) {
  const transformedStats = {};

  for (let monthNumber in stats) {
    const monthData = stats[monthNumber]

    transformedStats[monthNumber] = {
      lang_browser: [],
      lang_website: [],
      days: [],
    };

    Object.entries(monthData.lang_browser).forEach(([langKey, value]) => {
      transformedStats[monthNumber].lang_browser.push({
        name: langKey ? langKey.toUpperCase() : 'Unknown',
        value: value,
      });
    });

    Object.entries(monthData.lang_website).forEach(([langKey, value]) => {
      transformedStats[monthNumber].lang_website.push({
        name: langKey ? langKey.toLowerCase() : 'Unknown',
        value: value,
      });
    });

    const numberOfDaysInMonth = new Date(year, monthNumber, 0).getDate();

    for (let i = 0; i < numberOfDaysInMonth; i += 1) {
      const key = (i + 1).toString().padStart(2, '0')
      if (monthData.days[key]) {
        transformedStats[monthNumber].days.push({
          name: key,
          value: monthData.days[key].orders,
        });

      } else {
        transformedStats[monthNumber].days.push({
          name: key,
          value: 0,
        });
      }
    }

    transformedStats[monthNumber].lang_browser.sort((a, b) => b.value - a.value);
    transformedStats[monthNumber].lang_website.sort((a, b) => b.value - a.value);
  }

  return transformedStats;
}

export function getMaxValuesByMonths(stats) {
  // Count maximum values for lang_browser and lang_website for each month
  const maxValues = {};

  for (let monthNumber in stats) {
    const monthData = stats[monthNumber]

    const maxLangBrowserValue = monthData.lang_browser.reduce((acc, countryData) => (countryData.value > acc ? countryData.value : acc), 0);
    const maxLangWebsiteValue = monthData.lang_website.reduce((acc, countryData) => (countryData.value > acc ? countryData.value : acc), 0);
    const maxDaysValue = monthData.days.reduce((acc, dayData) => (dayData.value > acc ? dayData.value : acc), 0);

    maxValues[monthNumber] = {
      lang_browser: maxLangBrowserValue,
      lang_website: maxLangWebsiteValue,
      days: maxDaysValue,
    };
  }

  return maxValues;
}

export function getSumOfValuesByMonth(stats) {
  // Count sum of values for lang_browser and lang_website for each month
  const sumOfValues = {};

  for (let monthNumber in stats) {
    const monthData = stats[monthNumber]

    const sumOfLangBrowserValue = monthData.lang_browser.reduce((acc, countryData) => countryData.value + acc, 0);
    const sumOfLangWebsiteValue = monthData.lang_website.reduce((acc, countryData) => countryData.value + acc, 0);

    sumOfValues[monthNumber] = {
      lang_browser: sumOfLangBrowserValue,
      lang_website: sumOfLangWebsiteValue,
    };
  }

  return sumOfValues;

}