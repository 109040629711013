import React, { useState } from "react";
import CustomBadge from "../customBadge";
import { useRecoilState } from "recoil";
import { commentPopupAtom } from "../../atoms/commentPopupAtom";

import { ReactComponent as EditIcon } from "../../../static/svg/edit.svg";
import { ReactComponent as WhiteEditIcon } from "../../../static/svg/edit_white.svg";
import { ReactComponent as HoveredEditIcon } from "../../../static/svg/edit_hovered.svg";
import { Box } from "@mui/material";


export default function Comment({ row, name }) {
  const [, setCommentPopup] = useRecoilState(commentPopupAtom);
  const [isHovered, setIsHovered] = useState(false);

  function handleBadgeClick() {
    setCommentPopup(row);
  }

  if (row.comment) {
    return (
      <CustomBadge
        variant='red'
        label={1}
        onClick={handleBadgeClick}
        style={{
          cursor: 'pointer',
        }}
      />
    )
  } else {
    return (
      <Box
        onClick={handleBadgeClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}

        sx={{
          cursor: 'pointer',
          height: 24,
          width: 24,
        }}
      >
        {isHovered ? (
          <HoveredEditIcon />

        ) : (
          row.shouldHaveAccentBackground ? <WhiteEditIcon /> : <EditIcon />
        )}

      </Box>
    )
  }
}