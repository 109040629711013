export function getClubIdByName(clubs, clubName) {
  const clubData = Object.entries(clubs).find(([id, { name }]) => name === clubName);

  return clubData?.[0];
}

export function getClubNameById(clubs, id) {
  return clubs[id]?.name;
}

export function getClubColorById(clubs, id) {
  return clubs[id]?.color;
}