import { Typography } from "@mui/material";
import React from "react";
import BootstrapTooltip from "../customTooltip";
import { langs } from "../../../const/lang";

export default function Lang({ row, name, is_staff }) {

  if (row.is_view === true) {
    const lang = row[name].toUpperCase();
    const langParts = lang.split('-');
    const shortName = langParts[0] === langParts[1] ? langParts[0] : null;

    return (
      <BootstrapTooltip title={langs[langParts[0]?.toLowerCase()] || "Unknown"}>
        <Typography
          variant="table-text"
          sx={{
            color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
          }}
        >
          {is_staff 
            ? (shortName || lang) 
            : langParts[0]}
        </Typography>
      </BootstrapTooltip>
    )
  } else {
    return <></>
  }
}