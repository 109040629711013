export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const colorsByCountry = {
  'Unknown': '#ccc',
  '*': '#cccccc',
  "AF": "#718757",
  "AX": "#90ae51",
  "AL": "#ae7b5f",
  "DZ": "#93a6bb",
  "AS": "#63729a",
  "AD": "#9b387b",
  "AO": "#71b071",
  "AI": "#5a82b1",
  "AQ": "#456664",
  "AG": "#ad9681",
  "AR": "#225333",
  "AM": "#7f366f",
  "AW": "#95b486",
  "AU": "#abab36",
  "AT": "#6db754",
  "AZ": "#bb61a9",
  "BS": "#93549d",
  "BH": "#89b439",
  "BD": "#454b38",
  "BE": "#a89a78",
  "BZ": "#7b5798",
  "BJ": "#a5b29f",
  "BM": "#3d6993",
  "BT": "#783498",
  "BO": "#93847c",
  "BA": "#425cb9",
  "BW": "#a8766f",
  "BV": "#b45943",
  "BR": "#737366",
  "IO": "#bd4467",
  "BN": "#807a99",
  "BG": "#968185",
  "BF": "#64af6e",
  "BI": "#839e40",
  "KH": "#7889aa",
  "CM": "#7c4b80",
  "CA": "#339958",
  "CV": "#ad663f",
  "KY": "#86704c",
  "CF": "#81403c",
  "TD": "#3b36b3",
  "CL": "#696369",
  "CN": "#b94a48",
  "CX": "#72a14a",
  "CC": "#62408d",
  "CO": "#624ba3",
  "KM": "#5481af",
  "CG": "#9f3d33",
  "CD": "#b86060",
  "CK": "#499371",
  "CR": "#8e9589",
  "CI": "#724879",
  "HR": "#bdbb94",
  "CU": "#5b8762",
  "CY": "#7b6399",
  "CZ": "#8a5cad",
  "DK": "#af7439",
  "DJ": "#559f36",
  "DM": "#45a47c",
  "DO": "#7e96b1",
  "EC": "#b43ebd",
  "EG": "#8c916a",
  "SV": "#F9DA57",
  "GQ": "#3d676b",
  "EN": "#1D3178",
  "ER": "#5b7566",
  "EE": "#8868ae",
  "ET": "#ae7062",
  "FK": "#a6b799",
  "FO": "#6542a8",
  "FJ": "#576945",
  "FI": "#9BB8D7",
  "FR": "#6F3D79",
  "GF": "#bd4e6f",
  "PF": "#7fb8bc",
  "TF": "#bdae90",
  "GA": "#bb6b45",
  "GM": "#a769bc",
  "GE": "#b17576",
  "DE": "#D4A55E",
  "GH": "#9268b8",
  "GI": "#7cbd79",
  "GR": "#736253",
  "GL": "#603f6a",
  "GD": "#965a89",
  "GP": "#374bb9",
  "GU": "#96ae76",
  "GT": "#78596f",
  "GG": "#b83888",
  "GN": "#514d47",
  "GW": "#618433",
  "GY": "#b33e7d",
  "HT": "#594168",
  "HM": "#42ad9d",
  "VA": "#b3a188",
  "HN": "#7d8d3f",
  "HK": "#4d3cb3",
  "HU": "#6D6F40",
  "IS": "#ae3f6f",
  "IN": "#a84b82",
  "ID": "#ab5862",
  "IR": "#59aa76",
  "IQ": "#bda7af",
  "IE": "#b04072",
  "IM": "#b97faf",
  "IL": "#7bac6e",
  "IT": "#6A9451",
  "JM": "#71bdbd",
  "JP": "#4e594e",
  "JE": "#b26995",
  "JO": "#6eb25f",
  "KZ": "#38af5c",
  "KE": "#a34c57",
  "KI": "#83ac5c",
  "KP": "#73337e",
  "KR": "#617cb9",
  "KW": "#944f38",
  "KG": "#8b7c34",
  "LA": "#bdb679",
  "LV": "#2C6847",
  "LB": "#a8bd4c",
  "LS": "#50b148",
  "LR": "#529147",
  "LY": "#88ba40",
  "LI": "#808758",
  "LT": "#902E38",
  "LU": "#5fb850",
  "MO": "#b4ae6d",
  "MK": "#743e85",
  "MG": "#3b5e4f",
  "MW": "#7ba739",
  "MY": "#4f4b7a",
  "MV": "#61be40",
  "ML": "#62a194",
  "MT": "#42a3a2",
  "MH": "#637690",
  "MQ": "#a6a15e",
  "MR": "#40386f",
  "MU": "#737291",
  "YT": "#a5918c",
  "MX": "#9992a8",
  "FM": "#788852",
  "MD": "#8d8db6",
  "MC": "#a7bcb8",
  "MN": "#aeba33",
  "MS": "#69a553",
  "MA": "#5d9463",
  "MZ": "#a995b6",
  "MM": "#5e435b",
  "NA": "#706f90",
  "NR": "#ae837a",
  "NP": "#b6a3a0",
  "NL": "#D3723C",
  "AN": "#b0a5a7",
  "NC": "#a6865e",
  "NZ": "#b1989f",
  "NI": "#56ac59",
  "NE": "#3f714c",
  "NG": "#37567e",
  "NU": "#947b3d",
  "NF": "#ac9557",
  "MP": "#a4b24a",
  "NO": "#846950",
  "OM": "#bdbd91",
  "PK": "#4a5385",
  "PW": "#bb4079",
  "PS": "#6e8562",
  "PA": "#3ebea5",
  "PG": "#ad5eab",
  "PY": "#bbb9ba",
  "PE": "#667a97",
  "PH": "#7b5699",
  "PN": "#ba449e",
  "PL": "#71533F",
  "PT": "#2A6419",
  "PR": "#6d9499",
  "QA": "#35b484",
  "RE": "#64817e",
  "RO": "#B06024",
  "RU": "#9E3022",
  "RW": "#83825f",
  "SH": "#70338c",
  "KN": "#aeb74e",
  "LC": "#69ae3d",
  "PM": "#4c6771",
  "VC": "#b25d64",
  "WS": "#5c3682",
  "SM": "#596987",
  "ST": "#6c345a",
  "SA": "#983373",
  "SN": "#77473b",
  "CS": "#684127",
  "SC": "#af6585",
  "SL": "#7cb350",
  "SG": "#9f7f9d",
  "SK": "#766352",
  "SI": "#b04f3b",
  "SB": "#b16bba",
  "SO": "#67a5b9",
  "ZA": "#be63b3",
  "GS": "#b83cb1",
  "ES": "#F6C644",
  "LK": "#4c3851",
  "SD": "#71ab7a",
  "SR": "#1D3F72",
  "SJ": "#54b155",
  "SZ": "#bd8491",
  "SE": "#896ebd",
  "CH": "#3d9173",
  "SY": "#40a957",
  "TW": "#bd6b4e",
  "TJ": "#667c43",
  "TZ": "#9e3836",
  "TH": "#59bb94",
  "TL": "#824a8a",
  "TG": "#5677a6",
  "TK": "#6e784f",
  "TO": "#b97648",
  "TT": "#7cbe98",
  "TN": "#647d49",
  "TR": "#8B1D14",
  "TM": "#66a78f",
  "TC": "#9e949c",
  "TV": "#b4706e",
  "UG": "#924a3f",
  "UA": "#674fb8",
  "AE": "#8a4a6c",
  "GB": "#b78b42",
  "US": "#9983bc",
  "UM": "#327a4c",
  "UY": "#94aaa2",
  "UZ": "#52a379",
  "VU": "#a04657",
  "VE": "#9c7463",
  "VN": "#6a7fb7",
  "VG": "#7d4354",
  "VI": "#564084",
  "WF": "#6f69b3",
  "EH": "#773c77",
  "YE": "#948884",
  "ZM": "#bca0a5",
  "ZW": "#816242",
  "HE": "#6A98CC",
  "NB": "#C45324",
  "JA": "#7E2B20",
  "DA": "#B72B35",
  "UK": "#F8D949",
  "EL": "#2A5DA9",
  "ZH": "#DA3832",
  "EU": "#227799"
};