export default function getFormattedDatetime(date, dateOnly = false, timeOnly = false) {
  if (!date) return;

  const originalDate = new Date(date);
  const dateInTimezone = originalDate.toLocaleString("ru-RU", {timeZone: 'Europe/Madrid'});

  const [day, month, rest] = dateInTimezone.split(".");
  const year = rest.slice(2, 4);
  const [hours, minutes] = rest.slice(6).split(":");
  
  if (dateOnly) {
    return `${day}.${month}.${year}`;
  }  

  if (timeOnly) {
    return `${hours}:${minutes}`;
  }
  
  return `${day}.${month}.${year} ${hours}:${minutes}`;
}