import React from 'react';
import { Button, styled } from '@mui/material';

import { ReactComponent as PasswordIcon } from '../../../static/svg/password.svg';
import { ReactComponent as PasswordShownIcon } from '../../../static/svg/password_shown.svg';


const PasswordButton = ({ isPasswordShown, onClick }) => {
  return (
    <CustomButton onClick={onClick}>
      {isPasswordShown ? (
        <PasswordShownIcon />
      ) : (
        <PasswordIcon />
      )}
    </CustomButton>
  );
};

export default PasswordButton;

const CustomButton = styled(Button)(({ theme }) => ({
  minWidth: 32,
  width: 32,
  height: 32,
  padding: 4,
  borderRadius: 10,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  backgroundColor: 'rgba(243, 239, 232, 1)',

  '&:hover': {
    backgroundColor: 'rgba(27, 47, 43, 0.10)',
  },

  '&:active': {
    backgroundColor: 'rgba(27, 47, 43, 0.20)',
  },

  '@media (max-width: 1440px)': {
    minWidth: 26,
    height: 26,
    width: 26,

  }

}));