import React from "react";
import getFormattedDatetime from "../../../utils/getFormattedDatetime";
import { Typography } from "@mui/material";

export default function Time({ row, name }) {

  return (
    <Typography
      variant="table-text"
      sx={{
        color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit', 
      }}
    >
      {getFormattedDatetime(row[name], true)}&nbsp;&nbsp;{getFormattedDatetime(row[name], false, true)}
    </Typography>
  )
}