
import { ReactComponent as DesktopIcon } from '../../../static/svg/desktop.svg';
import { ReactComponent as TabletIcon } from '../../../static/svg/tablet.svg';
import { ReactComponent as PhoneIcon } from '../../../static/svg/phone.svg';
import { Box } from '@mui/material';

export default function Device({ row, isMac }) {
  const elementByType = {
    'mobile': <PhoneIcon width={isMac ? 20 : 24} />,
    'tablet': <TabletIcon width={isMac ? 20 : 24} />,
    'desktop': <DesktopIcon width={isMac ? 20 : 24} />,
  };

  if (!row.last_login_device) {
    return null;
  }

  return (
    <Box
      sx={{
        opacity: .75,
      }}
    >
      {elementByType[row.last_login_device]}
    </Box>
  );
}