import theme from "../../../theme"

export const smallTableStyles = {
  table: {
    style: {

    }
  },
  head: {
    style: {
      display: 'none'
    }
  },
  rows: {
    style: {
      color: "rgba(27, 47, 43, 0.80)",
      fontSize: 14,

      padding: '6px 12px',
      minHeight: 'unset',

      '&:not(:last-of-type)': {
        borderBottom: 'none',
      }
    }
  },
  cells: {
    style: {
      padding: '0 0 0 10px',
    }
  },
};

export const tableStyles = {
  table: {
    style: {
      border: '1px solid rgba(27, 47, 43, 0.10)',
      borderRadius: 24,
      overflow: 'hidden',

      backgroundColor: 'white',

      fontFamily: "Gilroy",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",

      // sort icon wrapper
      'span.__rdt_custom_sort_icon__': {
        width: 8,
        height: 8,
        marginLeft: 2,
        position: 'relative',
      },
    }
  },
  pagination: {
    style: {
      fontSize: '14px',

      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: theme.palette.background.default,
    }
  },
  headRow: {
    style: {
      backgroundColor: 'rgba(231, 243, 238, 1)',

      color: "rgb(27, 47, 43)",
      fontWeight: 1000,
      lineHeight: 1,
      fontSize: 14,
      textTransform: "uppercase",

      border: 'none',

      padding: '12px',
      minHeight: 'unset',
    },
  },
  rows: {
    style: {
      color: "rgba(27, 47, 43, 0.80)",
      fontSize: 14,

      padding: '6px 12px',
      minHeight: 'unset',
      
      '&:not(:last-of-type)': {
        borderBottom: 'none',
      },
      
      '&:last-of-type': {
        borderBottom: 'none !important',
      },
    
    }
  },
  headCells: {
    style: {
      padding: '0 0 0 10px',

      '&:last-of-type': {
        display: 'flex',
        justifyContent: 'center',
        
        padding: '0',
      }
    }
  },
  cells: {
    style: {
      padding: '0 0 0 10px',
    }
  },
  expanderButton: {
    style: {
      display: 'none',
    }
  },
  expanderCell: {
    style: {
      display: 'none',
    }
  }
};