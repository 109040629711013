import { Box, Button, Typography, styled, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as LogoOrange } from '../../static/svg/logo_header_orange.svg';
import { ReactComponent as LogoGreen } from '../../static/svg/logo_header_green.svg';
import { ReactComponent as LogoutIcon } from '../../static/svg/logout.svg';
import { ReactComponent as ArrowIcon } from '../../static/svg/arrow_black.svg';
import { useRecoilState } from 'recoil';
import { isUserLoggedAtom } from '../atoms/isUserLogged';
import { userAtom } from '../atoms/user';
import { orderDataAtom } from '../atoms/order';
import { logout } from '../api/auth';


const Header = ({ handleAddButtonClick }) => {
  const navigate = useNavigate();
  const isMac = useMediaQuery('(max-width: 1440px)');

  const [, setIsUserLogged] = useRecoilState(isUserLoggedAtom);
  const [, setUser] = useRecoilState(userAtom);
  const [, setOrders] = useRecoilState(orderDataAtom);

  const handleLogout = () => {
    logout({
      access: localStorage.getItem('access-token'),
    });

    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');

    setIsUserLogged(false);
    setUser(null);
    setOrders([]);

    navigate('/login');
  };

  return (
    <Wrapper>
      <LeftContainer>
        <Link to='/'>
          <Button variant='circle' sx={{ p: 0 }}>
            <Box
              sx={{ transform: 'rotate(90deg) translateY(5px)' }}
            >
              <ArrowIcon />
            </Box>
          </Button>
        </Link>

        {process.env.REACT_APP_PANEL_NAME === 'clubsbcn.online' ? (
          <LogoOrange width={isMac ? 155 : 186} height={isMac ? 30 : 36} />

        ) : (
          <LogoGreen width={isMac ? 155 : 186} height={isMac ? 30 : 36} />

        )}


      </LeftContainer>

      <CentralContainer>
        <NavLink isActive={true}>Users</NavLink>
      </CentralContainer>

      <RightContainer>
        <Button
          variant='action'
          sx={{ width: 115 }}
          onClick={handleAddButtonClick}
        >
          Add User
        </Button>

        <Button variant='circle' sx={{ p: 0 }} onClick={handleLogout}>
          <LogoutIcon />
        </Button>

      </RightContainer>

      {/* <Buttons>
        <Link
          to='/'
        >
          <NavLink variant='contained'>
            Главная
          </NavLink>
        </Link>
        <Link
          to='/admins/create'
        >
          <NavLink variant='contained'>
            Добавить администратора
          </NavLink>
        </Link>


      </Buttons> */}
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  padding: '6px 18px',
  marginBottom: 20,

}));

const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

const LeftContainer = styled(HorizontalContainer)(({ theme }) => ({
  width: 400,
}));

const CentralContainer = styled(HorizontalContainer)(({ theme }) => ({
  gap: 34,
}));

const RightContainer = styled(HorizontalContainer)(({ theme }) => ({
  width: 400,
  justifyContent: 'flex-end'
}));

const NavLink = styled(Typography)(({ isActive }) => ({
  color: isActive ? '#1B2F2B' : 'rgba(27, 47, 43, 0.60)',
  textAlign: "center",
  fontSize: 18,
  fontWeight: 1000,
  lineHeight: 1,
  textTransform: "uppercase",
  textDecoration: "none",
  position: 'relative',

  '&:hover': {
    color: isActive ? '#1B2F2B' : 'rgba(27, 47, 43, 0.80)',

  },

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -10,
    left: 0,

    backgroundColor: '#11BC76',
    width: isActive ? '100%' : 0,
    height: 4,
  },

  '@media (max-width: 1440px)': {
    fontSize: 16,
  },

}));