import { Box, Typography, styled } from '@mui/material';
import { ReactComponent as ArrowIcon } from '../../static/svg/arrow_black.svg';
import theme from '../../theme';


const SnackButton = ({ onClick, anchorEl }) => {
  return (
    <ButtonContainer
      id="basic-button"
      onClick={onClick}
    >
      <Typography
        sx={{
          color: 'black.secondary',
          opacity: .5,
        }}
      >
        Status
      </Typography>
      <Box
        sx={{
          height: 18,
          transition: `transform .2s ${theme.transitions.cubic}`,
          transform: `rotate(${anchorEl ? 180 : 0}deg)`,
        }}
      >
        <ArrowIcon />
      </Box>
    </ButtonContainer>
  );
};

export default SnackButton;

const ButtonContainer = styled(Box)(({ theme }) => ({
  height: 36,
  padding: "0 10px",
  borderRadius: 16,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,

  backgroundColor: 'white',

  cursor: "pointer",
  transition: `background-color .1s linear`,

  '&:hover': {
    backgroundColor: 'rgba(27, 47, 43, 0.04)',
  }

}));