import { Typography } from "@mui/material";
import React from "react";

export default function UTC({ row, name }) {

  if (row.is_view === true) {
    const utc = row[name];
    const output = utc > 0 ? `+${utc}` : utc;

    return (
      <Typography
        variant="table-text"
        sx={{
          color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
        }}
      >
        {output}
      </Typography>
    )
  } else {
    return <></>
  }
}