import React, { useEffect, useState } from "react";
// import Cookies from 'universal-cookie';
import LayOut from "../../components/layOut";
import {
  styled,
  FormHelperText,
  Button,
  Box,
  FormLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "../../const/url";
import customHistory from "../../routes/history";
import { useRecoilState } from "recoil";
import { isUserLoggedAtom } from "../../components/atoms/isUserLogged";

import { ReactComponent as LogoOrange } from '../../static/svg/logo_orange.svg';
import { ReactComponent as LogoGreen } from '../../static/svg/logo_green.svg';
import { ReactComponent as PersonIcon } from "../../static/svg/person.svg";
import PasswordButton from "../../components/buttons/passwordButton";

export default function LoginPage() {
  const [, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const [, setIsUserLogged] = useRecoilState(isUserLoggedAtom);
  
  const isMobile = useMediaQuery(`(max-width: 767px)`);
  const isDesktop = useMediaQuery(`(min-width: 1025px)`);

  const login = async (e) => {
    e.preventDefault();

    setLoading(true);
    setErrorMessage("");

    const loginData = {
      username: username,
      password: password,
      deviceType: isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet',
      device: navigator.userAgent,
    };

    try {
      const response = await axios.post(`${API_URL}auth/`, loginData);
      localStorage.setItem("access-token", response.data.access);
      localStorage.setItem("refresh-token", response.data.refresh);

      setIsUserLogged(true);

      customHistory.push('/?status=New');

    } catch (err) {
      setIsUserLogged(false);

      if (err.response?.status === 401) {
        setErrorMessage("Wrong credentials")
        setLoading(false)

      } else {
        console.log("error logging user", err)
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("access-token")) {
      setIsUserLogged(false);
    }
  }, []);

  return (
    <LayOut>
      <Wrapper>
        <Header>
          {process.env.REACT_APP_PANEL_NAME === 'clubsbcn.online' ? (
            <LogoOrange width={325} />

          ) : (
            <LogoGreen width={325} />

          )}
        </Header>
        <FormWrapper>
          <Form onSubmit={login}>


            <FormField>
              <Label>Login</Label>
              <Search>
                <PersonIcon />
                <Input
                  placeholder="Enter login"
                  autoComplete="username"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  error={errorMessage?.email ? true : false}
                />
              </Search>
            </FormField>

            <FormField>
              <Label>Password</Label>
              <Search
                sx={{
                  paddingLeft: 1,
                  gap: .5,
                }}
              >
                <PasswordButton
                  isPasswordShown={isPasswordShown}
                  onClick={() => setIsPasswordShown(!isPasswordShown)}
                />
                <Input
                  placeholder="Enter password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={errorMessage?.email ? true : false}
                  type={isPasswordShown ? 'text' : 'password'}

                />
              </Search>
            </FormField>

            <Button
              type='submit'
              variant="action"
              sx={{
                width: '100%',
                mt: 1.5,
                height: 48,

                '@media (max-width: 1440px)': {
                  height: 40,
                  fontSize: 16,
                },
              }}
            >Login</Button>

            {errorMessage && (
              <FormHelperText error={errorMessage ? true : false} size="Normal">
                {errorMessage}
              </FormHelperText>
            )}
          </Form>
        </FormWrapper>

        <Footer>
          <Typography sx={{ fontSize: 14 }}>
            Clubs BCN © All Rights Reserved - {new Date().getFullYear()}
          </Typography>
        </Footer>
      </Wrapper>
    </LayOut >
  );
}

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  height: '100vh',
}));

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',

  height: 68,
  padding: '6px 0',
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  height: '100%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Form = styled("form")({
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
  alignItems: "center",
  gap: 16,

  width: 315,
  margin: "40px 20px",
});

const FormField = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
}));

const Label = styled(FormLabel)(({ theme }) => ({
  textAlign: 'left',
  marginLeft: 10,
  fontWeight: 1000,
  color: '#1B2F2B',
  fontSize: 16,
  lineHeight: 1.4,
  textTransform: 'uppercase',
}));

const Search = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: "flex",
  width: "315px",
  height: "48px",
  padding: "0px 12px",
  alignItems: "center",
  gap: "8px",
  borderRadius: "16px",
  border: "1px solid rgba(27, 47, 43, 0.10)",
  background: "var(--Main-white, #FFF)",
  
  transition: 'all ease .2s',

  '&:hover': {
    border: "1px solid rgba(15, 190, 118, 0.30)",
    boxShadow: "0px 0px 0px 4px rgba(15, 190, 118, 0.15)"
  },

  '&:has(input:focus)': {
    border: "1px solid #0FBE76",
    boxShadow: "0px 0px 0px 4px rgba(15, 190, 118, 0.15)"
  },

  '@media (max-width: 1440px)': {
    height: "40px",


  }

}));

const Input = styled('input')(({ theme }) => ({
  color: '#434343',
  fontFamily: "Gilroy",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: 1,

  width: "100%",
  height: 30,
  opacity: .5,

  border: 'none',
  outline: 'none',

  transition: 'all ease .2s',

  '&:focus': {
    opacity: 1
  },

  '@media (max-width: 1440px)': {
    fontSize: 14,
  },
}));

const Footer = styled(Box)(({ theme }) => ({
  height: 68,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  marginBottom: 10,
}));

