import { axiosInstance } from "./axiosInstanse"


export const getOrderData = (params) => {
    return axiosInstance.get(`orders/`,{params})
}


export const patchOrderData = (data) => {
  return axiosInstance.patch(`orders/`, {data: data})
}