import {atom} from "recoil";


export const rowGroupSelect = atom({
  key: 'rowGroupSelect',
  default: [],
})


export const rowSelect = atom({
  key: 'rowSelect',
  default: [],
})
