import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useRecoilState, useRecoilValue } from "recoil";
import { ordersAtom } from '../../atoms/order';
import { userAtom } from '../../atoms/user';
import { getPatchedRows } from '../utils/order';
import { getCounters } from '../../api/counters';
import { countersAtom } from '../../atoms/counters';
import { localizationAtom } from '../../atoms/localization';


export default function Status({
  status,
  style,
  row,
  handleUpdate,
  buttonElement: ButtonElement,
  menuPosition = 'top',
  is_staff
}) {
  const [orders, setOrders] = useRecoilState(ordersAtom);
  const [, setCounters] = useRecoilState(countersAtom);
  const user = useRecoilValue(userAtom);
  const localization = useRecoilValue(localizationAtom);

  const isUserSuperadmin = user?.role === "superadmin";
  const isUserManager = user?.role === "manager";

  const [statusList, setStatusList] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    const isButtonDisabled = isUserManager || (!isUserSuperadmin && row?.status === "Registered" && new Date(row.date_visit).getTime() + 1000 * 60 * 10 < Date.now());

    if (isButtonDisabled) { 
      return; 
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusItemClick = async (item) => {
    if (handleUpdate) {
      handleUpdate(item);
      return;
    }

    const payload = [{ id: row.id, status: item }];

    getPatchedRows(payload, orders)
      .then((patchedOrders) => {
        setOrders(patchedOrders);
        setAnchorEl(null);
      })
      .catch(console.error);

    getCounters()
      .then((newCounters) => {
        setCounters(newCounters.data);
      })
      .catch(console.error);
  };

  React.useEffect(() => {
    setStatusList((isUserSuperadmin)
      ? ['New', 'Registered', 'Expired', 'Done', 'Deleted']
      : ['New', 'Registered']
    );
  }, [user]);

  return (
    <div>
      {ButtonElement ? (
        <ButtonElement onClick={handleClick} anchorEl={anchorEl} />
      ) : (
        <Button
          id="basic-button"
          aria-controls={isOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          onClick={handleClick}
          variant={status}
        >
          {(isUserSuperadmin || isUserManager)
            ? status
            : localization[`status_${status.toLowerCase()}`]}
        </Button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        sx={{
          zIndex: 2000,
          margin: '2px 0',

          '& .MuiMenu-paper': {
            borderRadius: 4,
            border: "1px solid rgba(27, 47, 43, 0.10)",
            background: "#FFF",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.05)",

          },

          '& .MuiList-root': {
            padding: 0,

          }
        }}

        anchorOrigin={{
          vertical: menuPosition === 'top' ? 'bottom' : 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: menuPosition,
          horizontal: 'left',
        }}
      >
        {
          statusList.map(item =>
            <MenuItem
              key={item}
              onClick={() => handleStatusItemClick(item)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: '12px 14px',
                borderBottom: "1px solid rgba(27, 47, 43, 0.10)",

              }}
            >
              <Button
                variant={item}
                key={item}
              >
                {(isUserSuperadmin || isUserManager)
                  ? item
                  : localization[`status_${item.toLowerCase()}`]}
              </Button>
            </MenuItem>
          )
        }
      </Menu>
    </div>
  );
}