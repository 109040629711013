import { Box, Button, Menu, MenuItem, styled } from '@mui/material';
import React from 'react';
import { useRecoilState } from 'recoil';

import EnglishFlag from './static/en.png';
import SpanishFlag from './static/es.png';
import ItalianFlag from './static/it.png';
import RussianFlag from './static/ru.png';

import { ReactComponent as ArrowIcon } from '../../static/svg/arrow_black.svg';

import { localizationAtom } from '../atoms/localization';
import { languages, localization } from '../../const/localization';
import theme from '../../theme';

const flagsByName = {
  en: EnglishFlag,
  es: SpanishFlag,
  it: ItalianFlag,
  ru: RussianFlag,
}

const LanguageButton = () => {
  const [{ lang }, setLocalization] = useRecoilState(localizationAtom);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleSelectLanguage = (lang) => {
    setLocalization(localization[lang]);
    localStorage.setItem('lang', lang);
    handleClose();
  };

  return (
    <>
      <LanguageWrapper
        open={isMenuOpen}
        disableRipple
        id='fade-button'
        aria-controls={isMenuOpen ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        <Language>
          <FlagImage src={flagsByName[lang]} alt="flag" />
          {lang}
        </Language>

        <ArrowIcon
          style={{
            transform: isMenuOpen ? 'rotate(180deg)' : '',
            transition: `transform .3s ${theme.transitions.cubic}`,
          }}
        />

      </LanguageWrapper>
      <Menu
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom', horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiMenu-paper': {
            display: 'inline-flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            
            marginTop: '4px',
            border: '1px solid rgba(27, 47, 43, 0.10)',
            borderRadius: '24px',
            
            background: '#FFF',
            boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.05)',

            '& ul': {
              width: '100%',
              paddingTop: '0px',
              paddingBottom: '0px',
            },
          },

          '& .MuiMenuItem-root:hover': {
            background: 'rgba(27, 47, 43, 0.10)',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
      >
        {languages.map(({ shortName, name }, index) => {
          return (
            <LanguageMenuItems
              disableRipple
              index={index}
              languages={languages}
              key={shortName}
              onClick={() => handleSelectLanguage(shortName)}
            >
              <FlagImage src={flagsByName[shortName]} alt="flag" />

              <LanguageText currentLang={lang} elem={shortName}>
                {name}
              </LanguageText>
            </LanguageMenuItems>
          );
        })}
      </Menu>
    </>
  );
};

export default LanguageButton;

const LanguageWrapper = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',

  height: '36px',
  padding: '0px 6px',

  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '140%',
  color: '#1B2F2B',

  borderRadius: '24px',
  border: '1px solid rgba(27, 47, 43, 0.10)',
  background: '#FFF',

  '&:hover': {
    background: 'rgba(232, 234, 234, 1)',
  },
  '&:active': {
    background: 'rgba(209, 213, 213, 1)',
  },
});

const Language = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  flex: '1 0 0',
});

const LanguageMenuItems = styled(MenuItem)(({ index, languages }) => ({
  borderBottom: index === languages.length - 1 ? '' : '1px solid rgba(27, 47, 43, 0.10)',
  display: 'flex',
  padding: '10px',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
}));

const LanguageText = styled('span')(({ currentLang, elem }) => ({
  color: currentLang.name === elem.name ? '#0FBE76' : '#1B2F2B',
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '140%',
}));

const FlagImage = styled('img')(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 24,

}));