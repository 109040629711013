import React from "react";
import { Typography } from "@mui/material";

export default function SimpoleOut({ row, name }) {

  if (row.is_view === true) {
    return (
      <Typography
        variant="table-text"
        sx={{
          color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit', 
        }}
      >
        {row[name]}
      </Typography>
    )
  } else {
    return <></>
  }
}