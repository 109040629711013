export function getWeekNumber() {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1); // Дата 1 января

  // Дата начала недели, содержащей 1 января
  const firstWeekOfYear = getStartOfWeekByDate(startOfYear);

  // Если начало недели выпадает на пт, сб или вс - то первая неделя текущего года - следующая
  if (startOfYear.getDay() > 4 || startOfYear.getDay() === 0) {
    firstWeekOfYear.setDate(firstWeekOfYear.getDate() + 7);
  }

  const diff = now - firstWeekOfYear;
  const oneWeek = 1000 * 60 * 60 * 24 * 7;
  const weekNumber = Math.floor(diff / oneWeek) + 1;

  return weekNumber;
}

function getStartOfWeekByDate(date) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - (newDate.getDay() || 7) + 1);
  return newDate;
}

function getWeeksOfYear(year) {
  if (!year) {
    year = new Date().getFullYear();
  }

  const weeks = {};
  const startOfYear = new Date(year, 0, 1); // Дата 1 января

  // Дата начала недели, содержащей 1 января
  const currentDate = getStartOfWeekByDate(startOfYear);

  // Если начало недели выпадает на пт, сб или вс - то первая неделя текущего года - следующая
  if (startOfYear.getDay() > 4 || startOfYear.getDay() === 0) {
    currentDate.setDate(currentDate.getDate() + 7);
  }
  
  // Для каждой недели получаю номер месяца, к которому она относится
  for (let i = 0; i < 52; i++) {    
    const monthNumber = currentDate.getMonth();
    
    const nextMonthStart = new Date(year, monthNumber + 1, 1);
    const nextMonthStartDayNumber = nextMonthStart.getDay();

    const firstDayOfNextMonth = (nextMonthStartDayNumber <= 4 && nextMonthStartDayNumber !== 0)
      ? new Date(year, monthNumber + 1, 1)
      : new Date(year, monthNumber + 1, 1 + 7);

    const firstWeekOfNextMonth = getStartOfWeekByDate(firstDayOfNextMonth);
    
    weeks[i + 1] = {
      start: currentDate.toDateString(),
      month: firstWeekOfNextMonth > currentDate 
        ? currentDate.getMonth() + 1
        : currentDate.getMonth() + 2,
    };

    currentDate.setDate(currentDate.getDate() + 7);
  }
 return weeks;
};

export function getMonthFromWeeksData(year) {
  const weeks = getWeeksOfYear(year);
  const res = [];

  Object.entries(weeks).forEach(([weekNumber, { month: monthNumber }]) => {
    if (+weekNumber === 52) {
      res.push(52);
      return;
    }

    if (monthNumber < weeks[+weekNumber + 1].month) {
      res.push(weekNumber);
    }
  });

  return res;
}