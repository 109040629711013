import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { CartesianGrid, XAxis, Bar, BarChart, LabelList, PieChart, Pie, Cell, Legend, Tooltip, YAxis } from 'recharts';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Box, Typography, styled, useMediaQuery } from '@mui/material';

import { getStats } from '../api/statistic';
import { getMaxValuesByMonths, getSumOfValuesByMonth, transformStatsForRechart } from './utils/stats';
import { colorsByCountry, months } from './consts';
import { localizationAtom } from '../atoms/localization';
import { userAtom } from '../atoms/user';

import Header from '../header';
import StatisticHeader from './statisticHeader';
import BarPopover from './barPopover';
import PiePopover from './piePopover';
import { BAR_COLOR, BAR_WEEKEND_COLOR } from '../../const/colors';
import DaysBarPopover from './daysDarPopover';
import { monthVariants } from '../../const/year';
import useWindowDimensions from '../../hooks/useWindowDimensions';


const StatisticPage = () => {
  const isMac = useMediaQuery('(max-width: 1440px)');

  const localization = useRecoilValue(localizationAtom);
  const user = useRecoilValue(userAtom);

  const isUserSuperadmin = user?.role === "superadmin";
  const isUserManager = user?.role === "manager";

  const { height } = useWindowDimensions();

  const [searchParams, setSearchParams] = useSearchParams();
  const [stats, setStats] = useState({});
  const [counters, setCounters] = useState({});
  const [days, setDays] = useState({});
  const [sumOfValues, setSumOfValues] = useState({});
  const [maxOfValues, setMaxOfValues] = useState({});

  const [isBarPopoverOpen, setIsBarPopoverOpen] = useState(false);
  const [hoveredBarData, setHoveredBarData] = useState({});

  const [isDaysBarPopoverOpen, setIsDaysBarPopoverOpen] = useState(false);
  const [hoveredDaysBarData, setHoveredDaysBarData] = useState({});

  const [isPiePopoverOpen, setIsPiePopoverOpen] = useState({});
  const [hoveredSectorData, setHoveredSectorData] = useState({});

  const [currentClubFilter, setCurrentClubFilter] = useState('all');

  const graphHeight = Math.max((height - 46 - 56 - 82 - 24 - 24 - 20) / 2, 260);
  
  const [month, setMonth] = useState(monthVariants[new Date().getMonth() + 1]);
  const year = searchParams.get('year') || new Date().getFullYear();

  //filter 
  function updateFilter(newFilters) {
    setSearchParams((prev) => {
      const curr = createSearchParams(prev);

      Object.entries(newFilters).forEach(([key, value]) => {
        if (value !== null) {
          curr.set(key, value);
        } else {
          curr.delete(key);
        }
      });

      return curr;
    });
  };

  function handleMouseOverBar(evt, monthData, countryName, monthNumber) {
    const currentBarValue = monthData.lang_browser.find((barData) => barData.name === countryName).value;

    setIsBarPopoverOpen(true);
    setHoveredBarData({
      name: countryName,
      value: currentBarValue,
      percent: Math.round(currentBarValue / sumOfValues[currentClubFilter][monthNumber].lang_browser * 100)
    });
  }

  function handleMouseOutBar() {
    setIsBarPopoverOpen(false);
  }

  function handleMouseOverDaysBar(evt, entry, monthNumber) {
    const dayData = days[currentClubFilter][monthNumber][entry.name];

    setIsDaysBarPopoverOpen(true);
    setHoveredDaysBarData({
      name: `${entry.name}.${monthNumber}`,
      value: dayData.orders,
      VT: (dayData.orders ? dayData.VT / dayData.orders * 100 : 0).toFixed(0),
    });
  }

  function handleMouseOutDaysBar() {
    setIsDaysBarPopoverOpen(false);
  }

  function handleMouseOverPie(evt, monthData, countryName, monthNumber) {
    const hoveredPieValue = monthData.lang_website.find((barData) => barData.name === countryName).value;


    setIsPiePopoverOpen((prev) => {
      const newState = { ...prev }
      newState[monthNumber] = true;
      return newState;
    });
    setHoveredSectorData({
      name: countryName,
      number: hoveredPieValue,
      value: Math.round(hoveredPieValue / sumOfValues[currentClubFilter][monthNumber].lang_browser * 100)
    });

  }

  function handleMouseOutPie(monthNumber) {
    setIsPiePopoverOpen((prev) => {
      const newState = { ...prev }
      newState[monthNumber] = false;
      return newState;
    });
  }

  useEffect(() => {
    setCurrentClubFilter((isUserSuperadmin || isUserManager)
      ? searchParams.get('club') || 'all'
      : user?.clubs[0].pk);
  }, [searchParams, user]);

  useEffect(() => {
    if (user?.role === "manager") {
      setSearchParams((prev) => {
        const curr = createSearchParams(prev);
        curr.set("club", user.clubs[0].pk);
        return curr;
      });
    }
  }, [user]);

  useEffect(() => {
    getStats(searchParams)
      .then(({ data }) => {
        const { orders } = data;

        const transformedOrdersData = Object.entries(orders).reduce((acc, [key, value]) => {
          acc[key] = transformStatsForRechart(value, year);
          return acc;
        }, {});

        const sumOfOrdersValuesData = Object.entries(transformedOrdersData).reduce((acc, [key, value]) => {
          acc[key] = getSumOfValuesByMonth(value);
          return acc;
        }, {});

        const maxOfOrderValuesData = Object.entries(transformedOrdersData).reduce((acc, [key, value]) => {
          acc[key] = getMaxValuesByMonths(value);
          return acc;
        }, {});

        const counters = Object.entries(orders).reduce((acc, [clubID, value]) => {
          const counters = Object.entries(value).reduce((acc, [month, monthData]) => {
            return { ...acc, [month]: { ...monthData.counters, avg: monthData.avg } };
          }, {});

          acc[clubID] = counters;
          return acc;
        }, {});

        const days = Object.entries(orders).reduce((acc, [clubID, value]) => {
          const days = Object.entries(value).reduce((acc, [month, monthData]) => {
            return { ...acc, [month]: monthData.days };
          }, {});

          acc[clubID] = days;
          return acc;
        }, {});


        setCounters(counters);
        setDays(days);
        setStats(transformedOrdersData);
        setSumOfValues(sumOfOrdersValuesData);
        setMaxOfValues(maxOfOrderValuesData)
      })
      .catch((err) => {
        console.log("Ошибка получения данных", err);

      });
  }, [searchParams]);

  const monthNumber = monthVariants.indexOf(month);
  const statsDataToRender = monthNumber === 0
    ? Object
      .entries(stats?.[currentClubFilter] || {})
      .sort((a, b) => a[0] - b[0])
    : Object
      .entries(stats?.[currentClubFilter] || {})
      .filter((monthData) => monthData[0] === String(monthNumber));

  return (
    <Container>
      <Header
        updateFilter={updateFilter}
        searchParams={searchParams}
      />

      <StatisticHeader
        updateFilter={updateFilter}
        searchParams={searchParams}
        setMonth={setMonth}
        month={month}
      />

      <StatsContainer>
        {Object.keys(stats?.[currentClubFilter] || {}).length > 0 ? (
          statsDataToRender
            .map(([monthNumber, data], index) => (
              <MonthContainer key={index}>
                <MonthHeader>
                  <MonthName variant='bold'>
                    {localization[`month_${months[monthNumber - 1].toLowerCase()}`]}
                  </MonthName>

                  {(isUserSuperadmin || isUserManager) && (
                    <CounterGroup>
                      <Counter>
                        <Typography
                          variant='bold'
                          sx={{
                            fontSize: 14,
                            color: '#1B2F2B',
                          }}
                        >
                          VT
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14 }}
                        >
                          {counters[currentClubFilter]?.[monthNumber]?.unique_orders_this_month_today_visit || 0}%
                        </Typography>
                      </Counter>

                      <Counter>
                        <Typography
                          variant='bold'
                          sx={{
                            fontSize: 14,
                            color: '#1B2F2B',
                          }}
                        >
                          AVG
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14 }}
                        >
                          {counters[currentClubFilter]?.[monthNumber]?.avg || 0}
                        </Typography>
                      </Counter>

                      <Counter>
                        <Typography
                          variant='bold'
                          sx={{
                            fontSize: 14,
                            color: '#1B2F2B',
                          }}
                        >
                          IP ES
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14 }}
                        >
                          {counters[currentClubFilter]?.[monthNumber]?.unique_orders_this_month_IP_ES || 0}%
                        </Typography>
                      </Counter>
                    </CounterGroup>
                  )}

                  <Box sx={{ width: 150 }} />
                </MonthHeader>

                <MonthBody>
                  {(isUserSuperadmin || isUserManager) && (
                    <BarChart
                      width={isMac ? 1374 : 1660}
                      height={graphHeight}
                      data={data.days}
                      style={{
                        boxSizing: 'border-box',
                        borderRadius: 6,
                        border: '1px solid rgba(27, 47, 43, 0.10)'
                      }}
                    >
                      <CartesianGrid stroke="rgba(27, 47, 43, 0.10)" vertical={false} />
                      <XAxis
                        dataKey="name"
                        height={30}
                        strokeWidth='0'
                        style={{
                          fill: "#1B2F2B",
                          fontFamily: "Gilroy",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: 700,

                        }} />
                      <YAxis
                        width={0}
                        domain={[0, maxOfValues[currentClubFilter][monthNumber].days * 1.2]}
                      />

                      <Bar
                        dataKey="value"
                        layout='horizontal'
                        maxBarSize={80}
                      >
                        {data.days.map((entry, index) => (
                          <Cell
                            radius={5}
                            key={`cell-${currentClubFilter}-${index}`}
                            fill={[0, 6].includes(new Date(year, monthNumber - 1, entry.name).getDay()) ? BAR_WEEKEND_COLOR : BAR_COLOR}
                            style={{
                              borderRadius: '5px',
                              cursor: 'pointer',
                            }}
                            onMouseEnter={(evt) => handleMouseOverDaysBar(evt, entry, monthNumber)}
                            onMouseOut={handleMouseOutDaysBar}
                          />
                        ))}

                        <LabelList
                          dataKey='value'
                          position="top"
                          formatter={(value) => value === 0 ? null : value}
                          style={{
                            fill: "#1B2F2B",
                            fontFamily: "Gilroy",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "100%",
                          }}
                        />
                      </Bar>

                    </BarChart>
                  )}


                  <BottomGraphs>
                    <BarChart
                      width={(isUserSuperadmin || isUserManager) ? 1000 : 1360}
                      height={graphHeight}
                      data={data.lang_browser}
                      style={{
                        boxSizing: 'border-box',
                        borderRadius: 6,
                        border: '1px solid rgba(27, 47, 43, 0.10)'
                      }}
                    >
                      <CartesianGrid stroke="rgba(27, 47, 43, 0.10)" vertical={false} />
                      <XAxis
                        dataKey="name"
                        height={30}
                        strokeWidth='0'
                        style={{
                          fill: "#1B2F2B",
                          fontFamily: "Gilroy",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: 700,

                        }} />
                      <YAxis
                        width={0}
                        domain={[0, maxOfValues[currentClubFilter][monthNumber].lang_browser * 1.1]}
                      />
                      <Tooltip
                        allowEscapeViewBox={{
                          x: true,
                          y: true,
                        }}
                        offset={0}
                        content={<BarPopover hoveredBarData={hoveredBarData} isBarPopoverOpen={isBarPopoverOpen} />}
                        isAnimationActive={false}
                        cursor={{ fill: 'transparent' }}
                        position={{
                          y: 400 - (hoveredBarData.value / maxOfValues[currentClubFilter][monthNumber].lang_browser * 330)
                        }}
                      />

                      <Bar
                        dataKey="value"
                        layout='horizontal'
                        maxBarSize={80}
                      >
                        {data.lang_browser.map((entry, index) => (
                          <Cell
                            radius={5}
                            key={`cell-${index}`}
                            fill={colorsByCountry[entry.name.toUpperCase()] || '#8884d8'}
                            style={{
                              borderRadius: '5px',
                              cursor: 'pointer',
                            }}
                            onMouseEnter={(evt) => handleMouseOverBar(evt, data, entry.name, monthNumber)}
                            onMouseOut={handleMouseOutBar}
                          />
                        ))}

                        <LabelList
                          dataKey='value'
                          position="top"
                          style={{
                            fill: "#1B2F2B",
                            fontFamily: "Gilroy",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "100%",
                          }}
                        />
                      </Bar>

                    </BarChart>

                    {(isUserSuperadmin || isUserManager) && (
                      <Box
                        sx={{
                          borderRadius: 2,
                          border: '1px solid rgba(27, 47, 43, 0.10)',
                          paddingBottom: 2.75,
                        }}
                      >
                        <PieChart
                          width={isMac ? 348 : 636}
                          height={graphHeight - 24}
                          style={{
                            boxSizing: 'border-box',

                            color: "#1B2F2B",
                            fontFamily: "Gilroy",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: 700,
                          }}
                          onMouseOut={() => handleMouseOutPie(monthNumber)}
                        >
                          <Pie
                            data={data.lang_website}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            legendType='circle'
                            outerRadius={graphHeight / 2 - 30}
                            startAngle={90}
                            endAngle={450}
                          >
                            {data.lang_website.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={colorsByCountry[entry.name.toUpperCase()] || '#8884d8'}
                                style={{
                                  cursor: 'pointer',
                                }}
                                onMouseOver={(evt) => handleMouseOverPie(evt, data, entry.name, monthNumber)}
                              />
                            ))}
                          </Pie>
                          <Legend
                            formatter={(value) => <span style={{ color: '#1B2F2B' }}>{value}</span>}
                          />

                          <Tooltip
                            content={<PiePopover hoveredSectorData={hoveredSectorData} isPiePopoverOpen={isPiePopoverOpen[monthNumber]} />}
                            animationDuration={50}
                            allowEscapeViewBox={{
                              x: true,
                              y: true,
                            }}
                          />
                        </PieChart>
                      </Box>
                    )}
                  </BottomGraphs>
                </MonthBody>
              </MonthContainer >
            ))) : (
          <p>Нет данных</p>
        )}

      </StatsContainer>



    </Container>
  );
};

export default StatisticPage;

const Container = styled(Box)(({ theme }) => ({
  maxWidth: 1728,
  width: '100%',
  margin: '0 auto',
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "0px 10px",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",

}));

const MonthName = styled(Typography)(({ theme }) => ({
  minWidth: 150,
}));

const CounterGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "34px",

}));

const Counter = styled(Box)(({ isColored }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "6px",

  height: '100%',
  padding: "0px 12px",

  backgroundColor: isColored ? '#368362' : 'transparent',
  color: isColored ? 'white' : 'inherit',

  border: '1px solid rgba(27, 47, 43, 0.10)',

  '&:not(:last-child)': {
    borderRight: "none",
  },

  '&:first-child': {
    borderRadius: '16px 0 0 16px',
  },

  '&:last-child': {
    borderRadius: '0 16px 16px 0',
  },

}));

const MonthContainer = styled(Box)(() => ({
  boxSizing: "border-box",
  borderRadius: "16px",
  background: "white",
  border: "1px solid rgba(27, 47, 43, 0.10)",

  width: "100%",
  overflowX: "auto",
  overflowY: "hidden",
}));

const MonthHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "24px 24px 24px 36px",
  alignItems: "center",
  gap: "20px",
  alignSelf: "stretch",
}));

const MonthBody = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0px 24px 24px 24px",
  alignItems: "flex-start",
  gap: "24px",

}));

const BottomGraphs = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  gap: "24px",

}));
