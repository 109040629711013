import React from "react";
import { Typography } from "@mui/material";


export default function Email({ row, name }) {
  if (row.is_view === true) {
    return (
      <Typography
        variant="table-text"
        sx={{
          fontWeight: 700,
          color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
          '&::after': {
            content: "''",
            display: "block",
          }
        }}
      >
        {row[name]}
      </Typography>
    )
  } else {
    return <></>
  }
}