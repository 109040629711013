import { Typography } from "@mui/material";
import getFormattedDatetime from "../../../utils/getFormattedDatetime";

export default function DateComponent({ row }) {
  if (!row.date_visit) return null;

  if (row.status === 'Registered') {
    return (
      <Typography
        variant="table-text"
        sx={{
          color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
        }}
      >
        {getFormattedDatetime(row.date_visit, true)}&nbsp;&nbsp;{getFormattedDatetime(row.date_visit, false, true)}
      </Typography>
    );
  }

  if (row.is_view) {
    return (
      <Typography
        variant="table-text"
        sx={{
          color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
        }}
      >
        {getFormattedDatetime(row.date_visit, true)}
      </Typography>
    );

  } else {
    return null;
  }
}