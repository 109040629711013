import React from 'react';
import SimpoleOut from './simpleOut'
import Club from "./club";
import Status from './statusButton';
import Time from './time'
import DateComponent from './dateComponent';
import CheackBoxRow from './checkBox';
import Country from './country';
import TypeScreen from './typeScreen';
import Name from './name';
import Email from './email';
import Lang from './lang';
import UTC from './utc';
import Comment from './comment';
import Referrer from './referrer';
import Delivery from './delivery';
import Br from './br';


function getColumns(isUserSuperadmin, localization, isMac) {
  return isUserSuperadmin ?
    [
      {
        name: '',
        cell: row => <CheackBoxRow row={row} />,
        width: isMac ? '40px' : '55px',
        style: {
          padding: 0,
          paddingLeft: isMac ? 5 : 20,
        }
      },
      {
        name: '№',
        selector: row => <SimpoleOut row={row} name={'id_year'} />,
        width: isMac ? '47px': '51px'
      },
      {
        name: 'Created',
        cell: row => (
          row.is_view ? (
            <Time row={row} name={'created_at'} />
          ) : (
            null
          )
        ),
        width: isMac ? '106px': '120px'
      },
      {
        name: '',
        selector: row => <TypeScreen row={row} isMac={isMac} />,
        width: isMac ? '32px': '36px'
      },
      {
        name: 'Delivery',
        cell: row => <Delivery row={row} />,
        width: isMac ? '110px': '132px'
      },
      {
        name: 'Email',
        selector: row => <Email row={row} name={'email'} />,
        width: isMac ? '200px' : '285px'
      },
      {
        name: 'Club',
        cell: row => <Club row={row} name={'club'} />,
        width: '140px'
      },
      {
        name: 'Visitors',
        selector: row => <Name row={row} name={'name'} />,
        width: isMac ? '170px': '200px'
      },
      {
        name: 'Status',
        cell: row => <Status status={row.status} row={row} is_staff={true} />,
        width: '109px'
      },
      {
        name: 'Visit date',
        cell: row => <DateComponent row={row} name={'date_visit'} />,
        width: isMac ? '106px': '120px'
      },
      {
        name: 'Lang',
        cell: row => <Lang row={row} name={'lang_browser'} is_staff={true} />,
        width: isMac ? '56px': '64px'
      },
      {
        name: 'Br',
        cell: row => <Br row={row} name={'lang_website'} />,
        width: isMac ? '34px': '40px'
      },
      {
        name: 'UTC',
        cell: row => <UTC row={row} name={'UTC'} />,
        width: isMac ? '43px': '47px'
      },
      {
        name: 'IP',
        cell: row => <Country row={row} />,
        width: isMac ? '40px': '44px'
      },
      {
        name: 'Referrer',
        cell: row => <Referrer row={row} name='referrer' />,
        width: isMac ? '110px': '176px',
      },
      {
        name: 'Com',
        cell: row => <Comment row={row} />,
        width: '40px',
        style: {
          display: 'flex',
          justifyContent: 'center',
        }
      },
    ] : [
      {
        name: localization?.['table_created'],
        cell: row => (
          row.is_view ? (
            <Time row={row} name={'created_at'} />
          ) : (
            null
          )
        ),
        width: '160px'
      },
      {
        name: '№',
        selector: row => <SimpoleOut row={row} name={'id_year'} />,
        width: '100px'
      },
      {
        name: localization?.['table_visitors'],
        selector: row => <Name row={row} name={'name'} />,
        width: '375px'
      },
      {
        name: localization?.['table_status'],
        cell: row => <Status status={row.status} row={row} is_staff={false} />,
      },
      {
        name: localization?.['table_visit_date'],
        cell: row => <DateComponent row={row} />,
        width: '140px'
      },
      {
        name: localization?.['table_lang'],
        cell: row => <Lang row={row} name={'lang_browser'} />,
        width: '100px'
      },
      {
        name: localization?.['table_IP'],
        cell: row => <Country row={row} />,
        width: '76px'
      },
      {
        name: localization?.['table_comment'],
        cell: row => <Comment row={row} />,
        width: '120px',
        style: {
          display: 'flex',
          justifyContent: 'center',
        }
      },
    ];
}

export default getColumns;
