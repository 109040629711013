import React from "react";
import { useRecoilState } from "recoil";
import { rowSelect } from "../../atoms/row";

import { CustomCheckbox } from "../customCheckbox";

export default function CheackBoxRow({ row, ...props }) {
  const [selectedRows, setSelectedRows] = useRecoilState(rowSelect)
  const isChecked = selectedRows.find(item => item.id === row.id) ? true : false


  const handleClick = (row) => {
    if (!isChecked) {
      setSelectedRows([...selectedRows, row])
    } else {
      setSelectedRows(selectedRows.filter(item => item.id !== row.id))
    }
  }

  return (
    <CustomCheckbox
      checked={isChecked}
      onChange={() => handleClick(row)}
      size="small"
      style={{ opacity: .7 }}
      icon={<></>}
      checkedIcon={<></>}
      indeterminateIcon={<></>}
      inputProps={{ 'aria-label': 'controlled' }}
      {...props}
    />
  )
}
