import React from 'react';
import CustomPopover from './customPopover';
import { Typography, styled } from '@mui/material';

const DaysBarPopover = ({ hoveredDaysBarData = {}, isDaysBarPopoverOpen, ...props }) => {
  return (
    <CustomPopover {...props} isOpen={isDaysBarPopoverOpen}>
      <Text>{hoveredDaysBarData.name}</Text>
      <Text sx={{ fontSize: 16, fontWeight: 700, marginTop: .5, }}>{hoveredDaysBarData.VT}%</Text>
    </CustomPopover>
  );
};

export default DaysBarPopover;

const Text = styled(Typography)(({ theme }) => ({
  color: "white",
  textAlign: "center",
  fontFamily: "Gilroy",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal"
}));