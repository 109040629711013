import React from "react";

import BootstrapTooltip from '../customTooltip';
import { Typography } from "@mui/material";

export default function Name({ row, name }) {

  return row[name].length > 27 ? (
    <BootstrapTooltip title={row[name]}>
      <Typography
        variant="table-text"
        sx={{
          fontWeight: 700,
          color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
        }}
      >
        {row[name]}
      </Typography>
    </BootstrapTooltip>
  ) : (
    <Typography
      variant="table-text"
      sx={{
        fontWeight: 700,
        color: row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : 'inherit',
      }}
    >
      {row[name]}
    </Typography>

  )
}