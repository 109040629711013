import { atom, selectorFamily } from "recoil";
import { getOrderData, patchOrderData } from "../api/order";

export const ordersAtom = atom({
  key: 'ordersAtom',
  default: [],
})

export const OrderSelector = selectorFamily({
  key: 'OrderSelector',
  get: (queryParams) => async () => {
    let response;
    
    try {
      response = await getOrderData(queryParams);

    } catch (err) {
      console.log("Ошибка получения данных", err);

      return [[], err];
    }

    const tableData = [...response.data]
    const id_list = []
    let lastCreatedDate;

    // create data for grouping and views function on table
    tableData.forEach((item, i) => {
      // sort in id_group
      const id_year_group = tableData.filter(element => element.id_year === item.id_year)
      item['len_names'] = id_year_group.length
      item['is_last'] = id_year_group[id_year_group.length - 1].id === item.id

      // is view in table
      if (!id_list.includes(item.id_year)) {
        item['is_view'] = true
      } else {
        item['is_view'] = false
      }
      id_list.push(item.id_year)



      item['shouldHaveBoldTopBorder'] = (
        queryParams.get('status') === 'All'
        && item.created_at.slice(0, 10) !== lastCreatedDate
        && i !== 0
      );
      lastCreatedDate = item.created_at.slice(0, 10);

      // for group_orders_24
      if (item.group_orders_24.length > 0) {
        const id_list_2 = []
        item.group_orders_24.forEach((x, i) => {
          x['i'] = i
          if (!id_list_2.includes(x.id_year)) {
            x['is_view'] = true
          } else {
            x['is_view'] = false
          }
          x['group_orders_24'] = []
          id_list_2.push(x.id_year)
        });
      };
    });
    return [tableData, null];
  },
})

export const OrderSelectorPatch = selectorFamily({
  key: 'OrderSelectorPatch',
  get: (queryParams) => async () => {
    let response;

    try {
      response = await patchOrderData(queryParams);

    } catch (err) {
      console.log("Ошибка получения данных", err);
      return [[], err];
    }

    const tableData = [...response.data]

    return [tableData, null];

  }
})


export const orderDataAtom = atom({
  key: "OrderDataAtom",
  default: []
})

