import { axiosInstance } from "./axiosInstanse";


export const logout = (payload) => {
  return axiosInstance.post(`auth/logout/`, payload, {
    headers: {
      "Authorization": `Bearer ${payload.access}`,
    },
  });
};
